<template>

  <!-- Delete Image Dialog -->
  <j-dialog
    dialog-name="delete-image"
    :value="$dialog.status('delete-image')"
    title="Confirm image deletion..."
    width="50%"
    class="delete-image-dialog"
    @cancel="handleCloseDialog"
  >
    <!-- Body -->
    <j-card-text class="mt-4">
      <ImageView
        :image="image"
        :edit-button="false"
      />
    </j-card-text>

    <!-- Options -->
    <template #options>
      <!-- Cancel -->
      <j-button
        label="Cancel"
        type="option"
        @action="handleCloseDialog"
      />
    </template>

    <!-- Actions -->
    <template #actions>
      <!-- Delete -->
      <j-button
        label="Delete"
        type="danger"
        @action="handleConfirmImageDeletion"
      />
    </template>
  </j-dialog>
</template>

<script>

// Components
import ImageView from '@/components/Media/Images/ImageView'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('media')

export default {
  name: 'DeleteImageDialog',
  // -------------
  // Components ==
  // -------------
  components: {
    ImageView
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    source: {
      type: Object,
      default: null
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'image'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'deleteImage'
    ]),
    handleConfirmImageDeletion() {
      this.deleteImage().then(() => {
        this.$dialog.close('delete-image')
      })
    },
    handleCloseDialog() {
      this.$dialog.close('delete-image')
    }
  }
}
</script>
