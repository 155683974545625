<template>

  <!-- Embed Listing -->
  <div class="embed-listing">

    <!-- Edit Embed -->
    <EmbedEdit
      v-if="editEmbed"
      :embed="embed"
    />
  
    <!-- View embed -->
    <EmbedView
      v-else
      :embed="listing"
      :editable="editable"
    />

  </div>
</template>

<script>

// Components
import EmbedEdit from './EmbedEdit'
import EmbedView from './EmbedView'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('media')

export default {
  name: 'EmbedListing',
  // -------------
  // Components ==
  // -------------
  components: {
    EmbedEdit,
    EmbedView
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    listing: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'embed'
    ]),
    editEmbed() {
      return this.listing.get('tag19') == this.embed.get('tag19')
    }
  }
}
</script>

<style lang="scss">

.embed-action {
  position: absolute;
  cursor: pointer;
}

</style>
