<template>

  <!-- Image Edit -->
  <j-card
    :title="formTitle"
    class="image-edit"
  >
    <j-card-text>
      <!-- Image Form -->
      <j-form model="media.image">

        <!-- Graphic -->
        <j-control name="cdn_src"
          :original-src="imageSource"
          :metadata="cdnMetadata"
          :processor="updateUpload"
          :crop-settings="image.featured ? this.featuredImageCropping : {}"
        />

        <!-- Title -->
        <j-control name="title" />

        <!-- Credit -->
        <j-control name="credit" />

        <!-- Caption -->
        <j-control name="caption" rows="1" />

        <!-- Alignment -->
        <j-control name="align" cols="6" />

        <!-- Featured Image -->
        <j-control name="featured" cols="6" @change="cropFeatureImage" />
      </j-form>
    </j-card-text>

    <!-- Actions -->
    <template #actions>
      <!-- Save -->
      <j-button
        label="Save"
        :processing="image.saving"
        :disabled="image.pristine"
        @action="handleSave"
      />
    </template>

    <!-- Options -->
    <template #options>
      <!-- Delete -->
      <j-button
        v-if="!image.saving && !image.isNew()"
        label="Delete"
        type="confirm"
        @action="handleDelete"
      />

      <!-- Reset -->
      <j-button
        v-if="!image.saving && image.modified"
        type="option"
        label="Reset"
        @action="handleReset"
      />

      <!-- Cancel -->
      <j-button
        v-else
        label="Cancel"
        type="option"
        @action="handleCancel"
      />
    </template>
  </j-card>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('media')

export default {
  name: 'ImageEdit',
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'image'
    ]),
    ...mapGetters([
      'cdnMetadata'
    ]),
    formTitle() {
      return this.image.isNew() ? 'New Image' : this.image.$.title
    },
    imageSource () {
      return `${this.image.src.display}?${Date.now()}`
    }
  },
  inject: ['featuredImageCropping'],
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'openCropper',
      'saveImage'
    ]),
    ...mapMutations([
      'NEW_IMAGE',
      'RESET_IMAGE'
    ]),
    updateUpload (upload) {
      delete upload.title // do not rename
      this.image.set(upload)
      this.image.set('featured', false)
    },
    cropFeatureImage () {
      if (this.image.featured) {
        this.openCropper()
      }
    },
    handleCancel() {
      this.NEW_IMAGE()
    },
    handleSave() {
      this.saveImage()
    },
    handleReset() {
      this.RESET_IMAGE()
    },
    handleDelete() {
      this.$dialog.open('delete-image')
    }
  }
}
</script>
