<template>

  <!-- Image Listing -->
  <div class="image-listing">

    <!-- Edit Image -->
    <ImageEdit
      v-if="editImage"
      :image="image"
    />
  
    <!-- View Image -->
    <ImageView
      v-else
      :image="listing"
      :editable="editable"
    />

  </div>
</template>

<script>

// Components
import ImageEdit from './ImageEdit'
import ImageView from './ImageView'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('media')

export default {
  name: 'ImageListing',
  // -------------
  // Components ==
  // -------------
  components: {
    ImageEdit,
    ImageView
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    listing: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'image'
    ]),
    editImage() {
      return this.listing.get('tag19') == this.image.get('tag19')
    }
  }
}
</script>

<style lang="scss">

.image-action {
  position: absolute;
  cursor: pointer;
}

</style>
