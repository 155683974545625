<template>

  <!-- Embed View -->
  <j-card class="embed-view">
    <div class="d-flex">

      <!-- Graphic -->
      <j-graphic
        :src="embedLogo"
        max-width="30px"
        class="embed-manager-graphic ma-4 mr-2"
        @click="handleEdit"
      />

      <!-- Heading -->
      <h2 class="align-self-center tile-headline">
        <j-icon
          v-if="embed.featured"
          icon="star"
          size="xs"
          class="mr-1"
        />
        {{ embed.title }}
      </h2>

      <!-- Edit Button -->
      <j-button
        v-if="editable"
        icon="edit"
        type="icon"
        class="align-self-center ml-auto"
        @action="handleEdit"
      />
    </div>
    <j-card-text class="pt-0">
      <div v-html="embed.source_code" />
    </j-card-text>
  </j-card>
</template>

<script>


// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('media')

export default {
  name: 'EmbedView',
  props: {
    embed: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    alignmentIcon() {
      switch (this.embed.align) {
        case 'fill':
          return 'exchange-alt'
        case 'left':
          return 'long-arrow-alt-left'
        case 'right':
          return 'long-arrow-alt-right'
        default:
          return 'exchange-alt'
      }
    },
    embedLogo () {
      return `/assets/img/embed-logos/${this.embed.embed_type}.png`
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'editEmbed'
    ]),
    handleEdit () {
      if (this.editable) {
        this.editEmbed(this.embed)
      }
    }
  }
}
</script>

<style lang="scss">

  .embed-manager-graphic {
    cursor: pointer;
    max-width: 100px;
    max-height: 100px;
  }
  
</style>
