<template>

  <!-- Embed Edit -->
  <j-card
    :title="formTitle"
    class="embed-edit">
    <j-card-text>
      <!-- Embed Form -->
      <j-form model="media.embed">

        <!-- Title -->
        <j-control name="title" />

        <!-- URL -->
        <j-control name="url" :disabled="true" />

        <!-- Caption -->
        <!-- <j-control name="caption" rows="2" /> -->

        <!-- Alignment -->
        <j-control v-if="embed.embed_type !== 'iframe'" name="align" cols="6" />

        <!-- Height -->
        <j-control v-if="embed.embed_type !== 'iframe'" name="height" cols="2" />

        <!-- Featured Embed -->
        <j-control name="featured" cols="4" />
      </j-form>
    </j-card-text>

    <!-- Actions -->
    <template #actions>
      <!-- Save -->
      <j-button
        label="Save"
        :processing="embed.saving"
        :disabled="embed.pristine"
        @action="handleSave"
      />
    </template>

    <!-- Options -->
    <template #options>
      <!-- Delete -->
      <j-button
        v-if="!embed.saving && !embed.isNew()"
        label="Delete"
        type="confirm"
        @action="handleDelete"
      />

      <!-- Reset -->
      <j-button
        v-if="!embed.saving && embed.modified"
        type="option"
        label="Reset"
        @action="handleReset"
      />

      <!-- Cancel -->
      <j-button
        v-else
        label="Cancel"
        type="option"
        @action="handleCancel"
      />
    </template>
  </j-card>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations, mapActions } = createNamespacedHelpers('media')

export default {
  name: 'EmbedEdit',
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'embed'
    ]),
    formTitle() {
      return this.embed.isNew() ? 'New Media' : this.embed.$.title
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'saveEmbed'
    ]),
    ...mapMutations([
      'NEW_EMBED',
      'RESET_EMBED'
    ]),
    handleCancel() {
      this.$dialog.close('add-embed')
      this.NEW_EMBED()
    },
    handleSave() {
      this.saveEmbed()
    },
    handleReset() {
      this.RESET_EMBED()
    },
    handleDelete() {
      this.$dialog.open('delete-embed')
    }
  }
}
</script>
