<template>

  <!-- Image Uploader -->
  <div class="image-uploader">
    <slot>
      <j-button label="Add Images" icon="image" block @action="handleUpload" />
    </slot>
  </div>
</template>

<script>

// https://cloudinary.com/documentation/upload_widget_reference#widget_parameters

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('media')

export default {
  name: 'ImageUploader',
  computed: {
    ...mapGetters([
      'cdnMetadata'
    ])
  },
  methods: {
    ...mapActions([
      'openUploader',
      'addUpload'
    ]),
    handleUpload() {
      this.openUploader({
        ...this.cdnMetadata,
        multiple: true,
        processor: this.addUpload
      })
    },

  }
}
</script>
