<template>

  <!-- Image View -->
  <ImageTile
    :image="image"
    class="image-view"
  >

    <!-- Actions -->
    <template #actions>
      <j-button
        icon="edit"
        type="icon"
        class="align-self-center float-right"
        @action="handleEdit"
      />
    </template>

    <!-- Graphic -->
    <template #graphic>

      <!-- Badge -->
      <j-graphic
        :src="imageSrc"
        class="image-manager-graphic"
        :class="{ editable }"
        @click="handleEdit"
      >

        <!-- Alignment / Edit Button -->
        <v-row
          class="lightbox white--text fill-height"
          align="center"
        >
          <v-col align="center">
            <j-icon
              v-if="editable"
              :icon="alignmentIcon"
              size="lg"
            />
          </v-col>
        </v-row>
      </j-graphic>
    </template>
  </ImageTile>
</template>

<script>

import ImageTile from '@/components/widgets/ImageTile'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('media')

export default {
  name: 'ImageView',
  components: {
    ImageTile
  },
  props: {
    image: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imageSrc() {
      return this.image.src.badge || this.image.src.display
    },
    alignmentIcon() {
      switch (this.image.align) {
        case 'center':
          return 'arrows-alt-h'
        case 'fill':
          return 'exchange-alt'
        case 'left':
          return 'long-arrow-alt-left'
        case 'right':
          return 'long-arrow-alt-right'
        default:
          return 'exchange-alt'
      }
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'editImage'
    ]),
    handleEdit () {
      if (this.editable) {
        this.editImage(this.image)
      }
    }
  }
}
</script>

<style lang="scss">

.image-manager-graphic {
  max-width: 100px;
  max-height: 100px;
  &.editable {
    cursor: pointer;
  }
}

</style>
