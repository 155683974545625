<template>
  <div class="embed-manager">

    <v-row v-if="editable && !$dialog.status('add-embed')">
      <v-col>
        <!-- Image Uploader -->
        <ImageUploader />
      </v-col>
      <v-col>
        <!-- Add Embed Button -->
        <j-button
          label="Add Embedded Media"
          icon="play-circle"
          block
          @action="handleAddEmbed"
        />
      </v-col>
    </v-row>

    <!-- Add Embed Form -->
    <AddEmbed
      v-if="$dialog.status('add-embed')"
      class="mb-4"
    />

    <!-- No Embedded Media Prompt -->
    <j-alert
      v-if="mediaCount === 0"
      message="No media"
      class="mb-0"
    />

    <!-- Image Listing -->
    <ImageListing
      v-for="image in images.getModels()"
      :key="image.id"
      :listing="image"
      :editable="editable"
      class="mb-2"
    />

    <!-- Embed Listing -->
    <EmbedListing
      v-for="embed in embeds.getModels()"
      :key="embed.id"
      :listing="embed"
      :editable="editable"
      class="mb-2"
    />

    <!-- Confirmation modal for deleting images -->
    <DeleteImageDialog />

    <!-- Confirmation modal for deleting embeds -->
    <DeleteEmbedDialog />
  </div>
</template>

<script>

// Components
import AddEmbed from '@/components/Media/Embeds/AddEmbed'
import DeleteEmbedDialog from '@/components/Media/Embeds/DeleteEmbedDialog'
import DeleteImageDialog from '@/components/Media/Images/DeleteImageDialog'
import EmbedListing from '@/components/Media/Embeds/EmbedListing'
import ImageListing from '@/components/Media/Images/ImageListing'
import ImageUploader from '@/components/Media/Images/ImageUploader'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions, mapMutations } = createNamespacedHelpers('media')

export default {
  name: 'MediaManager',
  // -------------
  // Components ==
  // -------------
  components: {
    AddEmbed,
    DeleteEmbedDialog,
    DeleteImageDialog,
    EmbedListing,
    ImageListing,
    ImageUploader
},
  // -------------
  // Properties ==
  // -------------
  props: {
    source: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  // ----------
  // Provide ==
  // ----------
  provide() {
    return {
      featuredImageCropping: this.source.featuredImageCropping
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'images',
      'embeds',
      'embed'
    ]),
    ...mapGetters([
      'loading',
      'mediaCount'
    ])
  },
  // ----------
  // Watches ==
  // ----------
  watch: {
    'source.tag19': {
      immediate: true,
      handler() { this.SET_SOURCE(this.source) }
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'saveEmbed',
    ]),
    ...mapMutations([
      'NEW_EMBED',
      'SET_SOURCE'
    ]),
    handleAddEmbed() {
      this.NEW_EMBED()
      this.$dialog.open('add-embed')
    },
    handleConfirmImageDeletion() {
      this.deleteImage()
    },
    handleCancelImageDeletion() {
      this.HIDE_DELETE_IMAGE_CONFIRMATION()
    }
  }
}
</script>
