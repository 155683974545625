<template>
  <j-tile
    :kicker="kicker"
    :headline="image.title"
    :headline-icon="image.featured ? 'star' : ''"
    :description="description"
  >
    <!-- Graphic -->
    <template #graphic>
      <slot name="graphic" />
    </template>

    <!-- Actions -->
    <template #actions>
      <slot name="actions" />
    </template>

    <!-- Footer -->
    <template #footer>
      <slot name="footer" />
    </template>
  </j-tile>
</template>

<script>

export default {
  name: 'ImageTile',
  // -------------
  // Properties ==
  // -------------
  props: {
    image: {
      type: Object,
      default: null
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    kicker () {
      let width = this.image.width
      let height = this.image.height
      if (this.image.cropping && this.image.cropping.width) {
        width = this.image.cropping.finalWidth
        height = this.image.cropping.finalHeight
      }
      return `${width}w x ${height}h`
    },
    description () {
      let description = this.image.caption || ''
      if (this.image.credit) {
        description = `${description} (${this.image.credit})`
      }
      return description.trim()
    }
  }
}
</script>
