<template>

  <!-- Delete Embed Dialog -->
  <j-dialog
    dialog-name="delete-embed"
    :value="$dialog.status('delete-embed')"
    title="Confirm media deletion..."
    width="50%"
    class="delete-embed-dialog"
    @cancel="handleCloseDialog"
  >
    <!-- Body -->
    <j-card-text class="mt-4">
      <EmbedView
        :embed="embed"
        :editable="false"
      />
    </j-card-text>

    <!-- Options -->
    <template #options>
      <!-- Cancel -->
      <j-button
        label="Cancel"
        type="option"
        @action="handleCloseDialog"
      />
    </template>

    <!-- Actions -->
    <template #actions>
      <!-- Delete -->
      <j-button
        label="Delete"
        type="danger"
        @action="handleConfirmEmbedDeletion"
      />
    </template>
  </j-dialog>
</template>

<script>

// Components
import EmbedView from '@/components/Media/Embeds/EmbedView'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('media')

export default {
  name: 'DeleteEmbedDialog',
  // -------------
  // Components ==
  // -------------
  components: {
    EmbedView
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'embed'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'deleteEmbed'
    ]),
    handleConfirmEmbedDeletion() {
      this.deleteEmbed().then(() => {
        this.$dialog.close('delete-embed')
      })
    },
    handleCloseDialog() {
      this.$dialog.close('delete-embed')
    }
  }
}
</script>
