<template>

  <!-- Add Embed -->
  <j-card
    :title="formTitle"
    class="add-embed"
  >
    <j-card-text>
      <!-- Embed Form -->
      <j-form model="media.embed">

        <!-- Title -->
        <j-control name="title" />

        <!-- Source -->
        <j-control name="source_code" />

        <!-- Source Check -->
        <v-col v-if="validSource" cols="12" class="py-0">
          <j-icon
            icon="check"
            color="green"
            class="ml-3 mr-1"
          />
          {{ embedName }}
        </v-col>

        <!-- Alignment -->
        <j-control name="align" cols="6" />

        <!-- Featured Embed -->
        <j-control name="featured" cols="6" />

      </j-form>
    </j-card-text>

    <!-- Actions -->
    <template #actions>
      <!-- Save -->
      <j-button
        label="Save"
        :processing="embed.saving"
        :disabled="!validSource"
        @action="handleSave"
      />
    </template>

    <!-- Options -->
    <template #options>
      <!-- Delete -->
      <j-button
        v-if="!embed.saving && !embed.isNew()"
        label="Delete"
        type="confirm"
        @action="handleDelete"
      />

      <!-- Reset -->
      <j-button
        v-if="!embed.saving && embed.modified"
        type="option"
        label="Reset"
        @action="handleReset"
      />
 
      <!-- Cancel -->
      <j-button
        v-else
        label="Cancel"
        type="option"
        @action="handleCancel"
      />
    </template>
  </j-card>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations, mapActions } = createNamespacedHelpers('media')

export default {
  name: 'AddEmbed',
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'embed'
    ]),
    formTitle() {
      return this.embed.isNew() ? 'New Media' : this.embed.$.title
    },
    parseIframe () {
      // Get source
      const source = this.embed.get('source_code')
      // Extract iframe
      let iframe = source.match(/(<iframe.*<\/iframe>)/)
      // Return iframe if found
      return iframe ? iframe[1] : ''
    },
    parseSrc() {
      // No iframe, no chance
      if (!this.parseIframe) return ''

      // Extract src
      const src = this.parseIframe.match(/src="([^"]*)"/)
      // Return src if found
      return src ? src[1] : ''
    },
    parseType () {
      // No src, no chance
      if (!this.parseSrc) return ''

      // Figure out source site
      const urlDomain = this.parseSrc.match(/^https:\/\/([^/]*)\//)
      if (urlDomain) {
        const map = {
          'anchor.fm': 'anchor',
          'bandcamp.com': 'bandcamp',
          'www.mixcloud.com': 'mixcloud',
          'w.soundcloud.com': 'soundcloud',
          'open.spotify.com': 'spotify',
          'player.vimeo.com': 'vimeo',
          'youtube.com': 'youtube',
          'www.youtube.com': 'youtube'
        }
        const site = map[urlDomain[1]]
        // Parse out natively supported sources
        var match
        switch (site) {
          case 'bandcamp':
            match = this.parseSrc.match(/^(.*album=[^/]*)/)
            return match ? match[1] : 'iframe'
          case 'soundcloud':
            match = this.parseSrc.match(/^(.*playlists\/[^&]*)/)
            return match ? match[1] : 'iframe'
          case 'spotify':
            match = this.parseSrc.match(/^(.*album\/[^?]*)/)
            return match ? match[1] : 'iframe'
          default:
            return site
        }
      } else {
        return 'iframe'
      }
    },
    parseHeight () {
      // Match attribute
      let sourceMatch = this.parseIframe.match(/height="(\d*)/)
      if (sourceMatch) {
        return sourceMatch[1]
      } else {
        // Match CSS
        sourceMatch = this.parseIframe.match(/height: ?(\d*)/)
        return sourceMatch ? sourceMatch[1] : ''
      }
    },
    embedName () {
      const map = {
        anchor: 'Anchor',
        bandcamp: 'Bandcamp',
        iframe: 'iframe',
        mixcloud: 'Mixcloud',
        soundcloud: 'Soundcloud',
        spotify: 'Spotify',
        vimeo: 'Vimeo',
        youtube: 'YouTube'
      }
      return this.parseType ? (map[this.parseType] || '') : ''
    },
    validSource () {
      return !!this.parseType
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'saveEmbed'
    ]),
    ...mapMutations([
      'NEW_EMBED',
      'RESET_EMBED'
    ]),
    // Handlers
    handleCancel() {
      this.$dialog.close('add-embed')
      this.NEW_EMBED()
    },
    handleSave() {
      const source = (this.parseType === 'iframe') ? this.parseIframe : this.parseSrc
      const height = this.parseHeight
      const embed_type = this.parseType
      this.embed.set({ source, height, embed_type })
      this.saveEmbed().then(() => {
        this.$dialog.close('add-embed')
      })
    },
    handleReset() {
      this.RESET_EMBED()
    },
    handleDelete() {
      this.$dialog.open('delete-embed')
    }
  }
}
</script>
